// lib/shopDetailsApi.ts
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { AppDispatch } from '../store/store';
import { fetchReviewsStart, fetchReviewsSuccess, fetchReviewsFailure } from '../store/slices/salonReviewSlice';
import { APOLLO_CLIENT_URI } from '../config/config';
import { setBookingDetailsState } from '../store/slices/bookingSlice';
 
const client = new ApolloClient({
  uri:`${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

const GET_BOOKING_DETAILS_QUERY = gql`
   query GetBookingCustomerDetails($bookingId: ID!) {
    getBookingDetailsById(booking_id: $bookingId) {
      booking_id
      shop_id
      professional_id
      user_id
      booking_customer_detail_id
      slot_timings
      appointment_date
      cost
      status
      services_ids
      packages_ids
    }
  }
`;


 
export const fetchBookingDetailsById = async (
    dispatch: AppDispatch,
    bookingId:string
   
  ): Promise<void> => {
    dispatch(fetchReviewsStart());
  
    try {
      const { data } = await client.query({
        query: GET_BOOKING_DETAILS_QUERY,
        variables: { bookingId },
     
      });
  
      dispatch(setBookingDetailsState(data.getBookingDetailsById ));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchReviewsFailure(error.message));
      } else {
        dispatch(fetchReviewsFailure("An unknown error occurred"));
      }
    }
  };