import React from 'react';
import { IoMdStar } from 'react-icons/io'; // Importing the IoMdStar icon
// import Reviews from './Reviews'; // Adjust the import path based on your project structure

// Define the type for the values array
interface ValueItem {
    id: number;
    title: string;
    value: string;
}

const WhyTrustStylin: React.FC = () => {
    const values: ValueItem[] = [
        {
            id: 1,
            title: 'Bookings',
            value: '500+',
        },
        {
            id: 2,
            title: 'Partners',
            value: '2+',
        },
        {
            id: 3,
            title: 'Rating',
            value: '4.8',
        },
    ];

    return (
        <div className="w-full">
            <div>
                <div>
                    <h4 className="text-xl md:text-2xl lg:text-4xl xl:text-[45px] text-texthero text-center">
                        Why Trust STYLIN?
                    </h4>
                    <div className="  flex gap-8 md:gap-10 lg:gap-20 items-center justify-center py-7 lg:py-14">
                        {values.map((item, key) => (
                            <div className="h-full md:space-y-7 text-center" key={item.id}>
                                <h5 className="text-DARKEST_GREY_L1 text-lg md:text-2xl">
                                    {item.title}
                                </h5>
                                <div className="flex items-center text-2xl lg:text-5xl font-bold text-texthero">
                                    <p>{item.value}</p>
                                    <IoMdStar className={`${key < 2 && 'hidden'}`} />
                                </div>
                            </div>
                        ))}
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default WhyTrustStylin;
