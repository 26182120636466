import React from 'react';

// Define the props type for GrowTogCard
interface GrowTogCardProps {
    growtogData: {
        id: number;
        imgUrl: string;
        title: string;
        description: string;
    }[];
}

const GrowTogCard: React.FC<GrowTogCardProps> = ({ growtogData = [] }) => {
    return (
        <div className="lg:flex items-center justify-center lg:gap-5 space-y-5 lg:space-y-0">
            {growtogData.map((item) => (
                <div key={item.id} className="gradient-border p-2 lg:p-4 lg:w-[400px] h-[200px] lg:h-[325px]">
                    <img src={item.imgUrl} alt={item.title} className="w-36 lg:w-auto m-auto" />
                    <h3 className="py-2 lg:py-6 text-texthero lg:text-xl font-medium text-center">{item.title}</h3>
                    <p className="text-DARKEST_GREY_L1 text-sm lg:text-base w-64 lg:w-72 m-auto text-center">{item.description}</p>
                </div>
            ))}
        </div>
    );
};

export default GrowTogCard;
