import { gql } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { APOLLO_CLIENT_URI } from '../../config/config';


const client = new ApolloClient({
  uri:  `${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

export const CREATE_SHOP_RATING_MUTATION = gql`
  mutation CreateShopRating(
    $shopId: ID!,
    $userId: ID!,
    $rating: Int!,
    $review: String!,
    $spinValue:String
    $reviewTags: [String!]!
    $bookingId:String!
  ) {
    createShopRating(
      shop_id: $shopId,
      user_id: $userId,
      rating: $rating,
      review: $review,
      review_tags: $reviewTags,
      spin_value:$spinValue
      booking_id:$bookingId
    ) {
      rating_id
      shop_id
      user_id
      rating
      review
      review_tags
      booking_id
      spin_value
      created_at
      services
    }
  }
`;

export { client };  // Export the client as well
