import React from "react";
import { Link } from "react-router-dom";
import { FaStar, FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
import { FaAngleRight, FaRegHeart } from "react-icons/fa6";
import { Salon } from "../../data/salonData";
// import { Salon } from "../../../data/salonData";

interface SalonCardProps {
  isSpec?: boolean;
  salonData: Salon[]; // Typing the salonData prop
  className?: string;
  heroClass?: string;
}

const SalonCard: React.FC<SalonCardProps> = ({
  isSpec = true,
  salonData,
  className = '',
  heroClass = ''
}) => {
  return (
    <React.Fragment>
      {salonData.map((salon, key) => (
        <main className="relative" key={salon.id}>
          <div
            className={`flex flex-col items-center md:items-start justify-center m-auto ${className}`}
            style={{
              border: "1px solid var(--Light-Grey-L2, #EBEBEB)",
              borderRadius: "16px",
            }}
          >
            <div className="relative">
              <img
                src={salon.salonImg}
                alt="Salon"
                className="w-full rounded-t-xl h-[150px] lg:h-[232px] object-cover"
              />
              <div className="absolute top-7 right-6 bg-white flex items-center justify-center w-8 h-8 rounded-full">
                <FaRegHeart className="text-lg" />
              </div>
            </div>
            <div className="p-2 md:p-4 w-full space-y-2">
              <div className="flex items-center relative w-full">
                <h1 className="text-sm md:text-base lg:text-xl font-semibold">
                  {salon.title}
                </h1>
                <div className="flex flex-1"></div>
                <div className="flex items-center gap-x-1 lg:gap-2 text-xs md:text-base">
                  <p className="font-semibold">{salon.rating}</p>
                  <FaStar className="text-[8px] text-primary md:text-base" />
                </div>
                <p
                  className={`absolute -bottom-5 right-0 text-xs lg:text-sm font-medium ${
                    salon.isOpen ? "text-green" : "text-red"
                  }`}
                >
                  {salon.isOpen ? "Open" : "Closed"}
                </p>
              </div>
              <div className="flex items-center pt-2 lg:pt-2.5 text-DARKEST_GREY_L1">
                <div className="flex items-center gap-1">
                  <MdOutlineLocationOn className="text-xs md:text-base" />
                  <h4 className="text-xs lg:text-sm xl:text-base font-medium">
                    {salon.location}
                  </h4>
                </div>
                <div className="flex flex-1"></div>
                <p className="italic text-[10px] md:text-xs">
                  {salon.distance} away
                </p>
              </div>
              <div className="flex flex-col lg:flex-row lg:items-center gap-y-2 lg:gap-5">
                <div className="flex items-center gap-1">
                  <IoIosTimer className="text-xs md:text-base" />
                  <p className="text-xs md:text-sm xl:text-base">
                    {salon.timings}
                  </p>
                </div>
                <div className="flex items-center gap-1">
                  <FaRegCalendarAlt className="text-xs md:text-base" />
                  <p className="text-xs md:text-sm xl:text-base">
                    {salon.holiday}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center text-DARKEST_GREY_L1 md:pt-2">
                <div className="bg-DARKEST_GREY_L4 w-max px-2 lg:px-3 md:py-1 rounded-full text-center">
                  <button className="text-[10px] md:text-sm font-medium">
                    {salon.salonType}
                  </button>
                </div>
                <div className="flex flex-1"></div>
                <Link to={salon.link??'/'}>
                  <div className="flex items-center gap-1 lg:gap-2">
                    <p className="text-[10px] md:text-sm text-texthero">
                      View More
                    </p>
                    <FaAngleRight className="text-[8px] md:text-sm lg:text-base font-light text-DARKEST_GREY_L1" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </main>
      ))}
    </React.Fragment>
  );
};

export default SalonCard;
