export     const loginInput:Array<{
    id:number,
    title:string,
    type:string,
    name:string
}>  = [
    {
        id:1,
        title:'Enter your Name',
        type:'text',
        name:'name'
    },
    {
        id:2,
        title:'Select your Gender',
        type:'dropDown',
        name:'gender'

    }, 
    {
        id:3,
        title:'Date Of Birth',
        type:'date',
        name:'dob'

    }, 
    {
        id:3,
        title:'WhatsApp Phone Number',
        type:'number',
        name:'mobile'
    }, 
   

]