
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICustomState {
  
  isPopOpen:boolean,
  openCustServicePop:boolean,
  openSharePop:boolean,
  openCustDetailsPop:boolean,
  openAllDrops:boolean,
  serviceRating:number,
  activeTags:string[],
  greetMsg:string,
  showSpin:boolean,
  spinValue?:string,
  userDetails:{user_id:string}
  isProfileCartOpen:boolean
}

const initialState: ICustomState = {
    isPopOpen:false,
    greetMsg :'',
    openSharePop:false,
    openCustServicePop:false,
    openCustDetailsPop:false,
    openAllDrops:false,
    showSpin:false,
    spinValue:'',
    userDetails:{user_id:""},
    serviceRating:0,
    activeTags:[],

    isProfileCartOpen:false
   
};

const customSlice = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    setPopOpenState(state, action: PayloadAction<boolean>) {
      state.isPopOpen = action.payload;
    },
    setGreetMsgState(state,action:PayloadAction<string>){
      state.greetMsg = action.payload;
    },
    setOpenSharePopState(state,action:PayloadAction<boolean>) {
      state.openSharePop = action.payload;
    },
    setOpenCustServieState(state,action:PayloadAction<boolean>) {
      state.openCustServicePop = action.payload;
    },
    setOpenCustDetailsState(state,action:PayloadAction<boolean>) {
      state.openCustDetailsPop = action.payload;
    },
    setOpenAllDropsState(state,action:PayloadAction<boolean>) {
      state.openAllDrops = action.payload;
      },
      setServiceRatingState(state,action:PayloadAction<number>) {
        state.serviceRating= action.payload
      },
      setShowSpinState(state,action:PayloadAction<boolean>){
        state.showSpin = action.payload
      },
      setIsProfileCartOpenState(state,action:PayloadAction<boolean>){
        state.isProfileCartOpen = action.payload
      },
      setActiveTagsState(state,action:PayloadAction<string[]>){
        state.activeTags = action.payload
      },
      setUserDetailsState(state,action:PayloadAction<{user_id:string}>){
        state.userDetails = action.payload
      },
      setSpinValueState(state,action:PayloadAction<string>){
        state.spinValue = action.payload
      }
   
  },
});

export const { setPopOpenState,setGreetMsgState,setOpenSharePopState,setOpenCustServieState,setOpenCustDetailsState,setOpenAllDropsState,setServiceRatingState,setShowSpinState,setIsProfileCartOpenState ,setActiveTagsState,setUserDetailsState,setSpinValueState} = customSlice.actions;
export default customSlice.reducer;
