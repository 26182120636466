import lucasLogo from '../assets/brand/lucaslogo.png'
import famousLogo from '../assets/brand/famousLogo1.png'

let brandNameValue: string  =''
let brandColorValue : string  = ''
let brandLogoValue: string  = '';

async function fetchBrandName(): Promise<void> {
  brandNameValue = 'Lucas';
}
 
async function fetchBrandColor(brandName: string): Promise<void> {
  brandColorValue='#0202020'
}


async function fetchBrandLogo(brandName: string): Promise<void> {
brandLogoValue = famousLogo
}

 
// Immediately fetch the brand name
fetchBrandName();
fetchBrandColor(brandNameValue);
fetchBrandLogo(brandNameValue);


// Export the brand name value
function getBrandName(): string  {
  return brandNameValue;
}

function getBrandColor():string {
  return brandColorValue;
}

function getBrandLogo ():string {
  return brandLogoValue;
}





export {getBrandName, getBrandColor,getBrandLogo };