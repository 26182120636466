import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoIosMenu, IoMdClose } from "react-icons/io";
// import UserPic from "../assets/custom/userPic.png";
import { IoDiamond } from "react-icons/io5";

interface NavheaderProps {
  custClass?: string;
}

interface MenuItem {
  id: number;
  name: string;
  link: string;
}

const NavBarHome: React.FC<NavheaderProps> = ({ custClass = "bg-white lg:text-texthero" }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeNav, setActiveNav] = useState<string | null>(
    sessionStorage.getItem("activeNav")
  );
  const [isFixed, setIsFixed] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const menuData: MenuItem[] = [
    { id: 1, name: "About Us", link: "/about" },
    { id: 2, name: "Blogs", link: "/blogs" },
    { id: 3, name: "Contact Us", link: "/contactus" }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0 && !isFixed) {
        setIsFixed(true);
      } else if (offset === 0 && isFixed) {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavClick = (index: number, link: string) => {
    sessionStorage.setItem("activeNav", (index + 1).toString());
    navigate(link);
  };

  const elementStyle: React.CSSProperties = {
    borderBottom: "2px solid transparent",
    backgroundImage: "linear-gradient(to right, #BF963E, #FFFBA4, #AD832D)",
    WebkitBackgroundClip: "border-box",
    backgroundOrigin: "border-box"
  };

  const textGrad: React.CSSProperties = {
    background:
      "var(--Golden-Crust, linear-gradient(84deg, #BF963E 3.41%, #FFFBA4 47.6%, #AD832D 91.34%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  };

  const borderStyle: React.CSSProperties = {
    borderRadius: "5px",
    border: "1px solid var(--Golden-Crust, #BF963E)"
  };

  return (
    <main className="h-14 lg:h-16">
      <header
        className={`w-full h-max ${custClass} fixed ${
          isFixed ? "2xl:w-[1700px]" : "relative"
        } font-inter shadow-lg px-7 py-2 z-50 lg:px-10 xl:px-24 lg:py-4 lg:pb-5 flex items-center justify-between`}
        style={{ overflow: "hidden" }}
      >
        <Link to="/">
          <h1
            className="font-michroma lg:text-3xl tracking-wider"
            onClick={() => {
              sessionStorage.setItem("activeNav", "0");
            }}
          >
            STYLIN
          </h1>
        </Link>

        <div className="hidden lg:flex lg:items-end xl:w-full lg:justify-end lg:gap-14">
          {/* <ul className="flex space-x-4 lg:space-x-14 lg:text-sm xl:text-base font-medium">
            {menuData.map((item, index) => (
              <li
                key={item.id}
                className={`relative cursor-pointer ${
                  location.pathname === item.link ? "lg:border-b-2 border-hero2" : ""
                } ${!(activeNav === item.id.toString()) && "underline-hover"}`}
                onClick={() => handleNavClick(index, item.link)}
              >
                <span className="block p-2">{item.name}</span>
              </li>
            ))}
          </ul> */}
        </div>

        <div className="lg:hidden flex justify-end items-end w-full">
          <button className="px-3 py-2 font-semibold rounded-md" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <IoMdClose className="text-2xl" /> : <IoIosMenu className="text-2xl" />}
          </button>
        </div>

        {isMobileMenuOpen && (
          <div className="bg-texthero lg:hidden fixed inset-0 top-10 z-50">
            <div className="relative">
              {/* <img src={UserPic} alt="User" className="h-40 w-full object-cover" style={elementStyle} /> */}
              <div className="absolute bottom-2 pl-4">
                <h2 className="text-white">Hello,</h2>
                <h1 className="text-xl font-medium text-white">Guest</h1>
              </div>
            </div>

            <div className="flex flex-col text-white font-medium items-end gap-y-7 px-8 py-9">
              {menuData.map((item) => (
                <Link
                  to={item.link}
                  key={item.id}
                  className="text-white"
                  onClick={toggleMobileMenu}
                >
                  {item.name}
                </Link>
              ))}

              <div className="w-full pt-12 lg:hidden">
                <button className=" border border-white w-full font-medium text-center px-6 py-3 rounded-md">
                  For Business
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="items-center lg:ml-10 hidden lg:flex lg:gap-5">
          <button className="bg-texthero text-white border w-max border-texthero font-medium text-sm xl:text-base lg:px-4 lg:py-1.5 xl:px-6 xl:py-3 rounded-md">
            For Business
          </button>
        </div>
      </header>
    </main>
  );
};

export default NavBarHome;
