import React, { FormEvent } from "react";
import { useDispatch } from "react-redux";
import { setOpenAllDropsState } from "../../store/slices/customSlice";

interface ISearchCardProps {
  searchResults: string[];
  clsState: [boolean, (value: boolean) => void][];
}

const SearchCard: React.FunctionComponent<ISearchCardProps> = ({ searchResults, clsState }) => {
  const dispatch = useDispatch();
  const [closePop, setClosePop] = clsState[0];

  const handleSuggestionClick = (e: FormEvent, id: string) => {
    e.preventDefault();

    const sectionElement = document.getElementById(id);

    if (sectionElement) {
      const rect = sectionElement.getBoundingClientRect();

      window.scrollTo({
        top: rect.top + window.scrollY - 220, // Adjust for sticky height and some padding
        behavior: "smooth",
      });
    }
  };


  return (
    <div className="w-full z-40 bg-white rounded-md mt-2 lg:h-auto lg:max-h-96 drop-shadow-lg overflow-y-auto p-3 md:px-7 md:py-6">
      {searchResults.length > 0 ? (
        <ul className="list-none p-0 m-0">
          {searchResults.map((result, index) => (
            <li
              key={index}
              className="py-2 px-4 border-b border-DARKEST_GREY_L3 text-sm md:text-base"
              onClick={(e) => {
                handleSuggestionClick(e, result.toLowerCase());
                setTimeout(() => {
                  setClosePop(true);
                }, 200);
              }}
            >
              {result}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-DARKEST_GREY_L2 text-sm md:text-base">
          Oops! No matches found. Try rephrasing your search.
        </p>
      )}
    </div>
  );
};

export default SearchCard;
