import React, { useState } from "react";
import NavBar from "../components/Layout/NavBar";
import SelectProfessional from "../components/OrderDetails/SelectProfessional";
import TimeSchedule from "../components/OrderDetails/TimeSchedule";
import Footer from "../components/Layout/Footer";
import OrderDetailsMain from "../components/OrderDetails/_OrderDetailsMain";
import BookingSummary from "../components/OrderDetails/BookingSummary";
import BookingStatusMain from "../components/Booking/_BookingStatusMain";

import { useSelector,useDispatch } from "react-redux";
import { RootState } from "../store/store";
import Cart from "../components/Cart/Cart";
import { setBookingFlow, setBookingState } from "../store/slices/bookingSlice";
import MobileCart from "../components/Cart/MobileCart";

const OrderDetails: React.FC = () => {
 const dispatch = useDispatch()
 let { isProfileCartOpen } = useSelector((state: RootState) => state.custom);
  const {isBookingFlow} = useSelector((state: RootState) => state.booking);

  const cartValues = 
    {
      title:' BOOKING SUMMARY',
        ctaName : 'Confirm Booking',
        onclick:()=>{
          dispatch(setBookingState('pending'))
          dispatch(setBookingFlow(true))

        }
    }
  


  return (
    <main className="w-f ull ">
      <NavBar />
      <section className={` ${isBookingFlow&&'bg-[#838382a2]'}  ${isProfileCartOpen?'-z-40':""} relative  layout layout-lg layout-xl  pt-7 pb-10 lg:pb-20`}>
        <div className="z- 40 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
          <BookingStatusMain />
        </div>
        <div className={` ${isBookingFlow&&' -z-40 '} flex items-start  lg:w-full `}>
          <div className={`w-full  md:w-[55%] lg:w-[65%] ${isBookingFlow&&' -z-30 '} `}>
            <OrderDetailsMain />
          </div>
          <div className="flex flex-1" />
          <div className={`${isBookingFlow&&' -z-30 '}   hidden md:block sticky top-0 md:pt-4 `}>
            <Cart summary={cartValues} />
          </div>
          <div className="md:hidden fixed bottom-1 px-1 left-0 w-full">
          <MobileCart summary={cartValues}/>

        </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default OrderDetails;
