import React from "react";
import { Link } from "react-router-dom";

export interface FooterProps {}

type elementType ={
  name:string,
  link:string
}

const Footer: React.FC<FooterProps> = () => {

    const footerElements:elementType[] = [
      {
        name:'Help & Support',
        link:'/helpandsupport'
      },
      {
        name:'Terms & Conditions',
        link:"/termsandcondition"
      },
      {
        name:'Privacy Policy',
        link:'/privacy'
      }
      ]

  return (
    <footer className="bg-texthero w-full px-4 md:px-6 lg:px-20 xl:px-24 pt-4 xl:pt-5  pb-6 xl:pb-7 flex flex-col md:flex-row   "> 
      <div className="text-white lg: space-y-2.5">
        <p className="text-xs md:text-sm lg:text-base  ">Powered by</p>
        <h1 className="font-michroma text-lg md:text-xl  ">STYLIN</h1>

        <p className=" text-[11px] text md:text-xs">Crafted with ❤️ in Hyderabad, India</p>
      </div>
      <div className="flex flex-1"/>
      <div className="text-white  text-sm flex flex-col lg:flex-row  gap-3 lg:gap-5 pt-8 md:pt-0" >
        {footerElements.map((item, index) => (
          <Link to={item.link}>

            <p key={index} className="lg:w-36">
                {item.name}
            </p>
          </Link>
      )  )}
      </div>
    </footer>
  );
};

export default Footer;
