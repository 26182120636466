import React, { useState, useEffect, ChangeEvent, WheelEvent, FC } from "react";

interface InputPPProps {
  id: string;
  parenetClassName?: string;
  className?: string;
  label?: string;
  labelClassName?: string;
  type: string;
  placeholder?: string;
  value?: string;
  tDate?: string;
  maxlengths?: string;
  maxDate?: string;
  errorState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  changeFunction?: (e: ChangeEvent<HTMLInputElement>) => void;
  brClass?: string;
  borderReq?: boolean;
  disableTrue?: boolean;
  required?: boolean;
}

const InputPPHome: FC<InputPPProps> = ({
  id,
  parenetClassName = "",
  className = "",
  label = "",
  labelClassName = "",
  type,
  placeholder = "",
  value = "",
  tDate = "",
  maxlengths = "",
  maxDate = "",
  errorState = [false, () => {}],
  changeFunction,
  brClass = "",
  borderReq = false,
  disableTrue = false,
  required = true,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [error, setError] = errorState;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleWheel = (e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur(); // Prevent scroll on number inputs
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Allow only alphabetic characters and spaces for "Name" input
    const filteredValue = name === "Name" ? value.replace(/[^a-zA-Z\s]/g, "") : value;

    setInputValue(filteredValue);

    // Trigger external change function if provided
    // if (changeFunction) {
    //   changeFunction({ target: { name, value: filteredValue } });
    // }
  };

  return (
    <div className={`${parenetClassName}`}>
      {label && (
        <label htmlFor={id} className={`${labelClassName}`}>
          {label} <span className={required ? "text-[#f45138]" : "hidden"}>*</span>
        </label>
      )}
      <br className={brClass} />
      <input
        type={type}
        id={id}
        maxLength={maxlengths ? Number(maxlengths) : undefined}
        autoComplete="off"
        disabled={disableTrue}
        min={tDate}
        max={maxDate}
        placeholder={placeholder}
        className={`${className} ${borderReq ? "border" : ""} transition-transform duration-300 ${
          error ? "border-[#d81111] animate-shake" : "border-[#d7d5d5]"
        } focus:outline-none`}
        onChange={handleChange}
        onWheel={handleWheel}
        name={id}
        value={inputValue}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()} // Prevent form submit on "Enter"
      />
      {error && (
        <h1 className="text-[#b54a39] text-sm">Please Fill the {label}</h1>
      )}
    </div>
  );
};

export default InputPPHome;
