import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { AppDispatch } from '../store/store';
import {fetchUserDetailsFailure,fetchUserDetailsStart,fetchUserDetailsSuccess} from '../store/slices/userAllCustomerDetailsSlice';
import { GetUserIdVars } from '../types/salonDetailsTypes';
import { GetAllCustomer } from '../types/bookingFlowTypes';
import { APOLLO_CLIENT_URI } from '../config/config';

const client = new ApolloClient({
  uri:  `${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

const GET_ALL_CUSTOMER_QUERY = gql`
  query GetAllCustomer($userId: ID!) {
    getAllCustomer(user_id: $userId) {
      user_id
      customers {
        booking_customer_detail_id
        full_name
        gender
        phone_number
        date_of_birth
        user_id
      }
    }
  }
`;

 
export const fetchUserAllCustomers = async (
  dispatch: AppDispatch,
  userId: string
): Promise<void> => {
  dispatch(fetchUserDetailsStart());
  console.log('Fetching user all customers...');

  try {
    console.log('Querying GraphQL API...');
    const { data } = await client.query<GetAllCustomer, GetUserIdVars>({
      query: GET_ALL_CUSTOMER_QUERY,
      variables: { userId },
     // Add this option to force a network request
      fetchPolicy: 'no-cache' 
    });
    console.log('Query executed successfully.');

    dispatch(fetchUserDetailsSuccess(data.getAllCustomer.customers));
  } catch (error) {
    console.error('Error fetching user all customers:', error);
    if (error instanceof Error) {
      dispatch(fetchUserDetailsFailure(error.message));
    } else {
      dispatch(fetchUserDetailsFailure("An unknown error occurred"));
    }
  }
};