import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // import styles
import { getSalonGallary } from '../../lib/salonProfileService';
import { IoMdStar } from 'react-icons/io';
import { ButtonUi } from './SalonGallary';
import { IoShareOutline, IoApps } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setOpenSharePopState, setPopOpenState } from '../../store/slices/customSlice';

interface ISalonGallaryProps {
  gallery: string[];
}

const SalonMobGallary: React.FC<ISalonGallaryProps> = ({ gallery }) => {
  const dispatch = useDispatch();
  let isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);

  const [autoPlay, setAutoPlay] = useState(false); // New state for controlling autoPlay

  useEffect(() => {
    // Ensure auto-play starts when component mounts
    const timer = setTimeout(() => {
      setAutoPlay(true); // Force auto-play to restart
    }, 2000);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  const handleUserInteraction = () => {
    setAutoPlay(false); // Stop autoPlay when the user interacts
    setTimeout(() => {
      setAutoPlay(true); // Restart autoPlay after a short delay
    }, 2000); // Delay before restarting auto-play
  };

  return (
    <div className={`${isPopActive ? "-z-50" : ""} md:hidden pt-5 review-carousel-container h-max`}>
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={autoPlay} // Controlled by state
        interval={4000}
        emulateTouch={true} // Allow touch events, but still control auto-play
        stopOnHover={false} // Ensure autoPlay continues on hover
        centerMode={false} // Disable center mode
        onChange={handleUserInteraction} // Listen for user interaction
        onSwipeStart={handleUserInteraction} // Detect swipe
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorClasses = isSelected ? 'indicator selected' : 'indicator';
          return (
            <li
              className={indicatorClasses}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {gallery.map((item, index) => (
          <div className="" key={index}>
            <img src={item} alt="salon gallery" className='rounded-lg h-[150px] object-cover' />
          </div>
        ))}
      </Carousel>
      <div className="relative">
        <div className="absolute right-3 bottom-14 cursor-pointer"
          onClick={() => {
            dispatch(setPopOpenState(true));
            dispatch(setOpenSharePopState(true));
          }}>
          <ButtonUi>
            <IoShareOutline className="w-3 md:w-max" />
          </ButtonUi>
        </div>
        <div className="absolute right-3 bottom-3">
          <ButtonUi>
            <IoApps className="w-3 md:w-max" />
          </ButtonUi>
        </div>
      </div>
    </div>
  );
};

export default SalonMobGallary;
