export const faqData = [
    {
        id: 1,
        title: "How do I book an appointment with a salon on Stylin?",
        des: "To book an appointment with a salon on Stylin, simply browse through the list of available salons, select the one you prefer, choose your desired service, stylist, date, and time, and proceed to confirm your booking. ",
    },
    
    {
        id: 2,
        title: "Is there a fee for booking appointments through Stylin?",
        des: "There is a very minimal convenience fee depending on the cart value for every booking which you can avoid by buying Stylin’s user membership. ",
    },
    {
        id: 3,
        title: "Can I cancel or reschedule my appointment?",
        des: "Yes, you can cancel or reschedule your appointment through the website itself. Simply navigate to your bookings, select the appointment you wish to modify, and follow the prompts to make changes.",
    },
    {
        id: 4,
        title: "What happens if I don’t show up at the salon due to any circumstances? ",
        des: "Absolutely! We take privacy seriously. Your information is securely stored and will only be used for communication-related to the Stylin app",
    },

    {
        id: 5,
        title: "How do I set up my salon profile on the stylin website?",
        des: "Click on Sign up now on the homepage for businesses and enter the needed information with contact details to complete the login process. After logging in, add pictures of the salon, set up the services, and pricing, and add facilities, team members etc. to complete your salon profile.",
    },
    {
        id: 6,
        title: "How do I manage my everyday appointments booked through Stylin?",
        des: "As a salon partner, you can manage appointments through the Stylin partner dashboard. Here, you can view, confirm, reschedule, or cancel appointments as needed.",
    },

    {
        id: 7,
        title: "Is there a fee for using Stylin's appointment booking feature as a partner?",
        des: "Stylin offers a range of subscription plans for salon partners, which include access to the appointment booking feature as part of the package. There are no additional fees for using this functionality.",
    },
    {
        id: 8,
        title: "How can I create and manage coupons or discounts for my salon on Stylin?",
        des: "As a salon partner, you can create and manage coupons or discounts through the Stylin partner dashboard. Simply navigate to the promotions section, set up your offers with relevant details, and track their performance.",
    },
];
