import React, { useState,useEffect } from "react";
import PopUpLayout from "../Layout/PopUpLayout";
import { Customer } from "../../types/bookingFlowTypes";
import Checkbox from "../Common/CheckboxPP";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  setOpenCustDetailsState,
  setPopOpenState,
} from "../../store/slices/customSlice";
import InputPP from "../Common/InputPP";
import DropdownPP from "../Common/DropdownPP";
import { loginInput } from "../../data/loginInputData";
import Login from "../Auth/Login";
import { setCustomerIdState } from "../../store/slices/bookingSlice";

interface ICustDetailsPopUpCardProp {
  custDetails: Customer[];
}
 

const CustDetailsPopUpCard: React.FC<ICustDetailsPopUpCardProp> = ({
  custDetails,
}) => {
  const dispatch = useDispatch();

  const theadData: String[] = ["Name", "Contact", "Gender", "Birth Date"];
  const ctaBtns: String[] = ["Add Another", "Done"];

  const [customerDetails, setCustomerDetails] = useState<Customer[]|[]>(custDetails)

  useEffect(()=>{
    setCustomerDetails(custDetails)
  },[custDetails])
 
  const handleClose = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(setOpenCustDetailsState(false));
    dispatch(setPopOpenState(false));
    setCustomerDetails(custDetails)
  };
  const handleAddCustomer = (e:React.FormEvent) =>{
    e.preventDefault();
    setCustomerDetails([])

  }
  return (
    <PopUpLayout>
      <div
        className={`md:w-full relative bg-white mx-14 md:mx-0  px-3 md:px-10 lg:px-32 py-2 md:py-7 lg:py-10 rounded-xl md:rounded-2xl  ${
          customerDetails.length > 0
            ? "h-[450px] md:h-[560px] lg:h-[500px] xl:h-[630px]  "
            : ""
        } overflow-y-auto hideScrollBar  `}
      >
        <IoClose
          className={`text-xl cursor-pointer absolute right-5 top-3 `}
          onClick={handleClose}
        />
        <h1
          className={`${
            customerDetails.length <1&&'hidden' } font-semibold lg:text-[22px] xl:text-2xl`}
        >
        Customer details 
        </h1>
        <div
          className={`${
            customerDetails.length > 0
              ? " pt-9 md:pt-4 lg:pt-6 h-72 md:h-96 lg:h-80 "
              : "pt-4"
          } overflow-auto hideScrollBar`}
        >
          {customerDetails.length > 0 ? (
            <div className="">
              {customerDetails.map((customer, index) => (
                <div className="" key={index}>
                  <div className="flex items-center gap-5 md:gap-7 lg:gap-10">
                    <span className="" onClick={()=>{
                      console.log('customer id',customer.booking_customer_detail_id)
                      dispatch(setCustomerIdState(+customer.booking_customer_detail_id))
                    }} >

                    <Checkbox   />
                    </span>
                    <div className="text-xs md:text-base">
                      <table className="flex gap-10 text-DARKEST_GREY_L1  ">
                        <thead>
                          <tr className="flex flex-col  items-start">
                            {theadData.map((item, index) => (
                              <th
                                className={`${
                                  !customer.date_of_birth &&
                                  index == 3 &&
                                  "hidden"
                                } font-normal`}
                                key={index}
                              >
                                {" "}
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="flex flex-col capitalize">
                            <td>{customer.full_name}</td>
                            <td>{customer.phone_number}</td>
                            <td>{customer.gender}</td>
                            <td>{customer.date_of_birth}</td>
                          </tr>
                          {/* Add more rows here */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr className="text-DARKEST_GREY_L2 my-4 md:my-2" />
                </div>
              ))}
            </div>
          ) : (
            <form action="">
              <Login isCustForm={true} />
            </form>
          )}
        </div>

        <p
          className={`${
            customerDetails.length < 1 && "hidden"
          } text-blue italic text-xs lg:w-80 pt-3 lg:pt-0 pb-8 md:pb-5`}
        >
          *We will use this information for communicating details about your
          bookings.
        </p>

        <div
          className={`${
            customerDetails.length < 1 && "hidden"
          } w-full flex justify-center gap-2.5`}
        >
          {ctaBtns.map((btn, index) => (
            <button
              className={`${
                index + 1 == 1
                  ? "border  border-texthero"
                  : "bg-texthero text-white"
              } w-32 md:w-44 py-1 md:py-2 rounded text-sm md:text-base `}
              onClick={index + 1 == 1 ? handleAddCustomer : handleClose}
            >
              {btn}
            </button>
          ))}
        </div>
      </div>
    </PopUpLayout>
  );
};

export default CustDetailsPopUpCard;
