import React, { FC } from "react";

interface DropDownPPProps {
  label?: string;
  className?: string;
  labelClassName?: string;
  parentClassName?: string;
  id: string;
  brClass?: string;
  value?: any;
  differentDrop?: boolean;
  required?: boolean;
  errorState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  options: string[];
  special?: boolean;
}

const DropDownPPHome: FC<DropDownPPProps> = ({
  label = "",
  className = "",
  labelClassName = "",
  parentClassName = "",
  id,
  brClass = "",
  value = "",
  differentDrop = false,
  required = true,
  errorState = [false, () => {}],
  options,
  special = false,
}) => {
  const [error, setError] = errorState;

  return (
    <div className={`${parentClassName}`}>
      {label && (
        <label htmlFor={id} className={`${labelClassName}`}>
          {label}
          <span className={required ? "text-[#f45138]" : "hidden"}>*</span>
        </label>
      )}
      <br className={brClass} />
      <select
        name={id}
        id={id}
        defaultValue={value}
        className={`${className} ${
          error
            ? "border-[#d81111] transition-transform duration-300 animate-shake"
            : "border-[#d7d5d5]"
        } rounded-md focus:outline-none`}
      >
        {options.map((item, index) => (
          <option
            key={index}
            id={item}
            value={item}
            // value={differentDrop ? (typeof item === "object" ? item.id : item) : item}
          >
            {item}
            {/* {differentDrop ? (typeof item === "object" ? item.value : item) : item} */}
          </option>
        ))}
      </select>
      {error && (
        <h1 className="text-[#b54a39] text-sm">Please Fill the {label}</h1>
      )}
    </div>
  );
};

export default DropDownPPHome;
