import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setSpinValueState } from '../../store/slices/customSlice';

// Wheel data
const data = [
  { option: '10% off on Membership' },
  { option: '10% off on Loreal Products' },
  { option: '5% off on  Hair Service' },
  { option: ' 5% off on Skin Services' },
  { option: '10% off on Packages' },
  { option: '10% off upto 1000' },
];

const SpinTheWheel: React.FC = () => {

  const dispatch = useDispatch();

    const ratingValue = useSelector((state:RootState)=>state.custom.serviceRating)
    const showSpin = useSelector((state:RootState)=>state.custom.showSpin)

  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState<string | null>(null);

  const handleSpinClick = () => {
    if (isSpinning) return;

    // Generate random rotation (between 2000 and 3000 degrees)
    const randomRotation = Math.floor(Math.random() * 1000) + 2000;
    const totalRotation = rotation + randomRotation;
    setRotation(totalRotation); // Ensure it rotates clockwise continuously
    setIsSpinning(true);

    // Calculate which prize is under the arrow
    setTimeout(() => {
      const normalizedRotation = totalRotation % 360;
      const prizeIndex = Math.floor((360 - normalizedRotation) / 60) % 6;
      const closestPrize = data[prizeIndex].option;

      setSelectedPrize(closestPrize);
      dispatch(setSpinValueState(closestPrize))
      setIsSpinning(false);
    }, 5000); // Matches animation duration (5 seconds)
  };

  return (
    <div className={` ${+ratingValue>3&&showSpin?'flex flex-col items-center justify-center':"hidden"}  py-8    `}>
      {/* Arrow Indicator */}
      {selectedPrize && (
        <div className="mt-4 text-xl font-bold text-gray-700">
          🎉 Selected Prize: {selectedPrize} 🎉
        </div>
      )}
      <div className="arrow-indicator">
        <span>▼</span>
      </div>

      {/* Wheel container */}
      <div
        className="wheel   "
        style={{
          transform: `rotate(${rotation}deg)`,
          transition: 'transform 5s cubic-bezier(0.33, 1, 0.68, 1)', // Smoother easing
        }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className="wheel-section text-center"
            style={{
              transform: `rotate(${index * 60}deg)`, // Dividing wheel into equal sections
            }}
          >
            <span
              className="wheel-text text-center -ml-10 -mt-4 w-14  text-xs flex justify-center items-start"
              style={{
                // transform: `rotate(-${index * 60}deg)`, // Align text in the center of each section
              }}
            >
              {item.option}
            </span>
          </div>
        ))}
      </div>

      {/* Spin button */}
      <button
        className={`mt-8 px-6 py-3 bg-texthero text-white rounded-lg font-bold transition-all duration-300 ${isSpinning ? 'cursor-not-allowed' : ''}`}
        onClick={handleSpinClick}
        disabled={isSpinning||(selectedPrize!==null&&true)}
      >
        {selectedPrize==null?'Spin the Wheel!':'Already Spinned'}
      </button>

      {/* Display selected prize */}
    
    </div>
  );
};

export default SpinTheWheel;
