import React, { useState, useRef, FormEvent } from "react";
import { getSalonCategories } from "../../lib/salonServicesService";
import { getBrandColor } from "../../lib/brandService";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import Carousel from "../Carousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setOpenAllDropsState } from "../../store/slices/customSlice";

interface ISalonCategoriesProps {}

const SalonCategories: React.FunctionComponent<ISalonCategoriesProps> = (
  props
) => {
 const dispatch = useDispatch()

  const [activeCatlog, setActiveCatlog] = useState<string>("Recommended");
  const brandColor = getBrandColor();

  console.log(
    'fetched brand oclor ',brandColor
  )

  // fetching  Subcategoris
  const { subCategories } = useSelector(
    (state: RootState) => state.salonServices
  );
  const salonCatelogs =
    subCategories?.map((category) => category.category_name) ?? [];

  const handleSuggestionClick = (e: FormEvent, id: string) => {
    e.preventDefault();

    const sectionElement = document.getElementById(id);

    if (sectionElement) {
      const rect = sectionElement.getBoundingClientRect();

      window.scrollTo({
        top: rect.top + window.scrollY - 200, // Adjust for sticky height and some padding
        behavior: "smooth",
      });
    }
  };

  let isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);
  return (
    <Carousel>
      {salonCatelogs.map((item, key) => (
        <div
          className={`${
            isPopActive ? "-z-50" : "z-auto"
          } px-5 lg:px-7 py-1.5 lg:py-2 rounded-full`}
          key={key}
          style={{
            backgroundColor: activeCatlog === item ? brandColor : "#EBEBEB",
          }}
          onClick={(e) => {
            setActiveCatlog(item);
            dispatch(setOpenAllDropsState(true));

            handleSuggestionClick(e, item.toLowerCase());
          }}
        >
          <h2
            className={`${
              activeCatlog === item ? "text-texthero" : "text-DARKEST_GREY_L1"
            } w-max text-xs lg:text-base  font-medium`}
          >
            {item}
          </h2>
        </div>
      ))}
    </Carousel>
  );
};

export default SalonCategories;
