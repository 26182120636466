import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import SearchCard from "../Ui/SearchCard";
import { setOpenAllDropsState } from "../../store/slices/customSlice";

interface ISearchItemPPProps {
  value: string;
  onChange: (value: string) => void;
}

const SearchItemPP: React.FunctionComponent<ISearchItemPPProps> = ({
  value,
  onChange,
}) => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState<string>('')
  const [closePop, setClosePop] = useState<boolean>(false)

  const extServices = useSelector(
    (state: RootState) => state.salonServices.subCategories || []
  );
  const extPackages = useSelector(
    (state: RootState) => state.salonPackages.packages || []
  );
  const safePackages = Array.isArray(extPackages) ? extPackages : [extPackages];

  const subCategoryNames: string[] = extServices.flatMap((category) =>
    category.subcategories.map((subcategory) => subcategory.sub_category_name)
  );

  // Extract all package names
  const packageNames: string[] = safePackages
    .map((group) => group.serviceObj.map((service) => service.package_name))
    .flat();
  const combinedNames: string[] = subCategoryNames.concat(packageNames);

  const [searchResults, setSearchResults] = useState<string[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOpenAllDropsState(true))

    setClosePop(false)
    onChange(event.target.value);
    setSearchString(event.target.value);
    const inputValue = event.target.value.toLowerCase();
    const filteredResults = combinedNames.filter((name) =>
      name.toLowerCase().includes(inputValue)
    );
    setSearchResults(filteredResults);
  };

  return (
    <div className="w-full relative">
      <div className="w-full flex items-center gap-2 md:gap-3 border border-DARKEST_GREY_L3 rounded-md px-3 py-1.5 md:px-4 md:py-2">
        <IoSearch className="text-DARKEST_GREY_L3 text-lg md:text-xl font-semibold" />
        <input
          type="text"
          className="text-sm md:text-base focus:outline-none w-full"
          placeholder="Find services and packages here"
          value={value}
          onChange={handleInputChange}
        />
      </div>
      <div className={`${(!(searchString.trim().length>0)||closePop)&&'hidden'} absolute w-full z-50   `}>
        <SearchCard clsState={[[closePop, setClosePop]]} searchResults={searchResults} />
      </div>
    </div>
  );
};

export default SearchItemPP;
