import React from 'react';
import NavBar from '../components/Layout/NavBar';
import Footer from '../components/Layout/Footer';
import Section1 from '../components/Support/Section1';
import FAQ from '../components/Support/FAQ';
import Section2 from '../components/Support/Section2';


const HelpAndSupport :React.FC =()=>{
    return (
        <main className="2xl:w-[1700px] 2xl:m-auto ">
            <NavBar   />
            <div className="  ">
                <Section1 />
            </div>
           
            <div className="mx-5 md:mx-14 lg:mx-60  ">
                <FAQ />
            </div>
            <div className="lg:pt-20">
                <Section2 />
            </div>
            <Footer />
        </main>
    )
}


export default HelpAndSupport;