import React,{useState,useEffect} from 'react'
import DropdownPP from '../Common/DropdownPP';
import RatingBar from './Reviews/RatingBar';
import { MdOutlineVerifiedUser } from "react-icons/md";
import SalonReviewCard from '../Ui/SalonReviewCard';
import { getSalonReviews } from '../../lib/salonReviewLib'; 
import { ReviewsState } from '../../types/salonServicesTypes';

 

const SalonReview:React.FunctionComponent= () =>{
   const [ratingType, setRatingType] = useState<number|string>(0)
   const [sortType, setSortType] = useState<string>('')

   //  const salonReviews = getSalonReviews();
    const sortByVal = ['Rating','Price','Recommended']

    const handleChange = (val:string)=>{
      setSortType(val)
    }

    return (
       <div className="">
        <h1 className="text-texthero font-semibold md:text-[22px] xl:text-2xl pl-2 lg:pl-4">
        Customer Reviews
        </h1>
           <hr className="text-DARKEST_GREY_L3 border-b lg:mt-3 xl:mt-4" />

           <div className="pt-4 lg:pt-6 pl-2 lg:pl-4  flex items-center">
            <h2 className="text-texthero lg:text-xl xl:text-2xl">Summary</h2>
           <div className="flex flex-1  "/>
          
           {/* <p className="text-DARKEST_GREY_L1  text-sm lg:text-base pr-2 ">
           Sort By
           </p>
           <DropdownPP
              custClsName="md:w-40"
              isSpecFunType="gender"
              value={sortType}
              onChangeFun={handleChange}
              dropDefVal="Relevance"
              options={sortByVal}
              /> */}
           
           </div>
           <div className="pl-2 lg:pl-4 lg:w-[65%] lg:max-w-[65%] ">
            <RatingBar ratingType={ [ratingType, setRatingType]}/>
            <div className="w-full flex  items-center gap-1.5 lg:gap-3 bg-[#E9F2FF] border border-blue lg:px-3 px-2 xl:px-4 py-1 lg:py-2 mt-3 lg:mt-4  rounded-md ">
       <MdOutlineVerifiedUser className='text-3xl lg:text-lg'/>
       <h4 className="lg: text-xs xl:text-sm   font-medium">
       All our ratings are from genuine customers, with verified visits.
       </h4>
       
            </div> 
            
           </div>
           <div className="px-3 lg:px-8 pt-4  lg:pt-8  ">

           <SalonReviewCard  />
           </div>
           
       </div>
    )
}

export default SalonReview;