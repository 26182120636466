import React, { useState,useEffect,FormEvent,useRef } from "react";
import { FaChevronUp } from "react-icons/fa";
import { getBrandColor } from "../../lib/brandService";
import { setOpenAllDropsState } from "../../store/slices/customSlice";
import { useDispatch } from "react-redux";
// import { ExtractUserGender } from "../../utils/userProfile";

interface ISalonFeaturesProps {
  onIconClick?: (sectionId: "services" | "packages" |"offers"   | "reviews" | "about") => void;
  activeSection: "services" | "packages" | "offers" | "reviews" | "about";
  activeSectionVal?:string|null
}

const SalonFeatures: React.FunctionComponent<ISalonFeaturesProps> = ({ onIconClick, activeSection,activeSectionVal }) => {

 


  const featureOptions: Array<"services" | "packages" |"offers"  | "reviews" | "about"> = [
    "services",
    "packages",
    "offers" ,
    "reviews",
    "about",
  ];
  const brandColor = getBrandColor();

  console.log('current active secton ',activeSectionVal)

// function  to scroll to top 
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSuggestionClick = (e: FormEvent, id: string) => {
    e.preventDefault();


    const sectionElement = document.getElementById(id);

    if (sectionElement) {
      const rect = sectionElement.getBoundingClientRect();

      window.scrollTo({
        top: rect.top + window.scrollY  -170, // Adjust for sticky height and some padding
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="overflow-x-auto w-full relative h-10 hideScrollBar lg:overflow-hidden ">
      <div className="w-full flex items-center space-x-6 md:space-x-0 md:justify-around">
        <FaChevronUp className="hidden lg:block text-DARKEST_GREY_L2 text-sm md:text-base absolute md:relative left-0 cursor-pointer"   onClick={handleScrollToTop} />
        {featureOptions.map((item) => (
          <div
            className="relative flex flex-col justify-center items-center cursor-pointer"
            key={item}
            onClick={(e) =>handleSuggestionClick(e,item)}
          >
            <p
              className={`${
                activeSectionVal === item ? "font-semibold" : "text-DARKEST_GREY_L2"
              } text-xs md:text-sm lg:text-base`}
              style={{
                color: activeSectionVal === item ? brandColor : "",
              }}
            >
              {item.toUpperCase()}
            </p>
            <hr
              className={`absolute top-7 w-20 lg:w-28 -z-50 ${
                activeSectionVal === item ? "" : "hidden"
              } lg:border-[1.4px]`}
              style={{
                color: brandColor,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalonFeatures;
