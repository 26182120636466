import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import SalonProfile from "../pages/SalonProfile";
import OrderDetails from "../pages/OrderDetails";
import Login from "../components/Auth/Login";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { RootState } from "../store/store";
import {
  setLoginState,
  setUserAuth,
  setUserDetails,
  setUserName,
} from "../store/slices/authSlice";

import { IinitialUsertype } from "../types/userAuthTypes";
import ServiceReview from "../pages/ServiceReview";
import { Loader } from "../components/Ui/Loader";
import { setPopOpenState } from "../store/slices/customSlice";
import { setShopId } from "../store/slices/salonDetailsSlice";
import { extractShopIdFromUrl } from "../utils/salonProfile";
import { getUserDetails } from "../service/userAuthService";
import TermsAndConditions from "../pages/TermsAndConditions";
import Privacy from "../pages/Privacy";
import HelpAndSupport from "../pages/HelpAndSupport";
import { fetchUserAllCustomers } from "../service/userCustomerDetailsService";
import HomeLanding from "../pages/HomeLanding";

const initialUserValues = {
  name: "",
  gender: "",
  dob: "",
  mobile: "",
};

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoginTrue } = useSelector((state: RootState) => state.login);
  const isPopOpen = useSelector(
    (state: RootState) => state.custom.openSharePop
  );
  const salonDetailsLoaded = useSelector(
    (state: RootState) => state.salonDetails.loading
  );
  const salonOffersLoaded = useSelector(
    (state: RootState) => state.salonOffers.loading
  );
  const salonServiceLoaded = useSelector(
    (state: RootState) => state.salonServices.loading
  );
  const salonPackagesLoaded = useSelector(
    (state: RootState) => state.salonPackages.loading
  );

  const userCustomerDetails = useSelector(
    (state: RootState) => state.userAllCustomerDetails.customers
  );

  console.log("curent customers", userCustomerDetails.length);

  // const salonPackagesLoaded = useSelector((state:RootState)=>state.salonPackages.loading)

  const shopId = extractShopIdFromUrl();

  const jwt_token = localStorage.getItem("auth_tokens");

  const [isUserAuthenticated, setIsUserAuthenticated] = useState<
    boolean | string
  >(localStorage.getItem("is_loggedin_") === "true");

  const [defuserDetails, setDefUserDetails] =
    useState<IinitialUsertype>(initialUserValues);
  const [showLoader1, setShowLoader1] = useState<boolean>(false);
  const [user_values, setUser_values] = useState<{
    user_id: "";
  }>({ user_id: "" });

  const [JWT_TOKEN, setJWT_TOKEN] = useState<{ accessToken: "" }>({
    accessToken: "",
  });

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    if (user_id) {
      setUser_values(JSON.parse(user_id));
    }
  }, [user_id]);

  useEffect(() => {
    dispatch(setShopId(shopId ? `${shopId}` : ""));
  }, [shopId]);

  useEffect(() => {
    const isUserLoggedin = localStorage.getItem("is_loggedin_") === "true";
    setIsUserAuthenticated(isUserLoggedin);
  }, []);
  useEffect(() => {
    const sessUserDetails = localStorage.getItem("user_details");
    if (sessUserDetails) {
      setDefUserDetails(JSON.parse(sessUserDetails));
    }
  }, []);

  useEffect(() => {
    if (jwt_token) {
      setJWT_TOKEN(JSON.parse(jwt_token));
    }
  }, [jwt_token]);

  // Show Loader
  // useEffect(() => {
  //   if (
  //     salonDetailsLoaded ||
  //     salonOffersLoaded ||
  //     salonServiceLoaded ||
  //     salonPackagesLoaded
  //   ) {
  //     setShowLoader1(true);
  //   } else {
  //     setShowLoader1(false);
  //     setTimeout(() => {
  //       dispatch(setPopOpenState(false));
  //     }, 1000);
  //   }
  // }, [
  //   salonDetailsLoaded,
  //   salonOffersLoaded,
  //   salonPackagesLoaded,
  //   salonServiceLoaded,
  // ]);

  // Update the State from Session Storage Values
  useEffect(() => {
    dispatch(setUserName(defuserDetails && defuserDetails.name));
    dispatch(setUserDetails(defuserDetails));
  }, [defuserDetails, dispatch]);

  useEffect(() => {
    fetchUserAllCustomers(dispatch, user_values.user_id);
  }, [dispatch, user_values.user_id]);

  useEffect(() => {
    // if (!isUserAuthenticated && !showLoader1) {
    //   setTimeout(() => {
    //     dispatch(setLoginState(true));
    //   }, 3000);
    // }

    if (isUserAuthenticated) {
      dispatch(setUserAuth(true));
    }
  }, [isUserAuthenticated, showLoader1]);

  useEffect(() => {
    getUserDetails(JWT_TOKEN.accessToken).then((userDetails) => {
      if (userDetails) {
        setUser_values(userDetails);
    localStorage.setItem('user_id',JSON.stringify(userDetails))

      }
    });
  }, [JWT_TOKEN.accessToken]);

  useEffect(() => {
    // Add the class to prevent body scrolling when popup is open
    if (isPopOpen) {
      document.body.classList.add("no-scroll");
    }

    // Cleanup function to remove the class when popup is closed
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isPopOpen]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLanding />} />
        {/* ------------ shop Routs ---------- */}
        <Route
          path="/:shopName/:city/salonProfile"
          element={<SalonProfile />}
        />
        <Route
          path="/:shopName/:city/orderDetails"
          element={<OrderDetails />}
        />
        <Route path="/:shopName/:city/review" element={<ServiceReview />} />

        {/* --------- Custom Route--------------- */}
        <Route path="/termsandcondition" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/helpandsupport" element={<HelpAndSupport />} />
      </Routes>
      <div
        className={` ${
          isLoginTrue && !showLoader1 ? "fixed" : "hidden "
        }   inset-0  flex items-center justify-center bg-opacity-50 backdrop-filter backdrop-blur-lg   bg-[#29292b81] bg-blend-saturation h-screen w-full 2xl:w-[1700px]  z-10 `}
      >
        <Login />
      </div>
      {/* <div
        className={` ${
          showLoader1 ? "fixed  z-50" : "hidden "
        }   inset-0  flex items-center justify-center bg-opacity-50 backdrop-filter backdrop-blur-lg   bg-[#29292b81] bg-blend-saturation h-screen w-screen 2xl:w-[1700px]  `}
      >
        <Loader />
      </div> */}
    </>
  );
};

export default AppRoutes;
