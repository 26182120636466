import React,{useState,useEffect} from "react";

import img1 from "../../assets/booking/status1.png";
import BookingStatusCard from "../Ui/BookingStatusCard";

import { useDispatch, useSelector } from "react-redux";
import { gql, useMutation } from "@apollo/client";


import { setBookingFlow, setBookingState } from "../../store/slices/bookingSlice";
import { bookingStateValues } from "../../config/parameters";
import { CREATE_BOOKING_MUTATION } from "../../service/mutations/createBookingMutation";
import { extractShopIdFromUrl } from "../../utils/salonProfile";
import { RootState } from "../../store/store";
import { convertTo24Hour } from "../../utils/slotSchedule";
import { useParams } from "react-router-dom";

const ConfirmBooking: React.FC = () => {
  const dispatch = useDispatch();
  const [createBooking] = useMutation(CREATE_BOOKING_MUTATION);
  const salonId = extractShopIdFromUrl();
  const professional = useSelector((state:RootState)=>state.booking.selectedProfessionId)
  const customerId = useSelector((state:RootState)=>state.booking.customerId)
  const selected_timings = useSelector((state:RootState)=>state.booking.selectedTime)
  const {selectedDate}  = useSelector((state:RootState)=>state.booking)
  const totalPrice = useSelector((state:RootState)=>state.userCart.total)
  const service_Ids = useSelector((state:RootState)=>state.userCart.addedServices)

  const user_id = localStorage.getItem("user_id")
  const subCategoryIds = service_Ids.map((service) => service.sub_category_id);


  const [user_values, setUser_values] = useState<{
    user_id:''
  }>({user_id:''})


  useEffect(()=>{
    if(user_id) {
      setUser_values(JSON.parse(user_id))
    }
  },[user_id])


  console.log('selected timeing',  selected_timings.map((slot_time) =>
    convertTo24Hour(slot_time)
  ))
 


  const btnActions: Array<{
    id: number;
    btnName: string;
    btnAction: () => void;
    custClassName?: string;
  }> = [
    {
      id: 1,
      btnName: "Review Booking",
      btnAction: () => {
        dispatch(setBookingState(''));
        dispatch(setBookingFlow(false))
      },
      custClassName: "bg-white text-texthero",
    },
    {
      id: 2,
      btnName: "Confirm Booking",
      btnAction: async() => {
        dispatch(setBookingState(bookingStateValues[1]));

       
        try {
          const { data } = await createBooking({
            variables:{
              "shopId": salonId,
              "professionalId": professional?.toString(),
              "userId":user_values.user_id,
              // "bookingCustomerDetailId": '1',
              "bookingCustomerDetailId": customerId??user_values.user_id,
              "slotTimings":selected_timings.map((slot_time) =>
                convertTo24Hour(slot_time)
              ),
              "appointmentDate": selectedDate,
              "cost": totalPrice,
              "status": "accepted",
              "paymentStatus": "Not Paid",
              "servicesIds": subCategoryIds,

   
            }
            
            
          });
    
          console.log("Booking created with ID:", data.createBooking.booking_id);

        } catch (error) {
          console.error("Error creating booking:", error);
        }
      },
      custClassName: "bg-texthero text-white",
    },
  ];

  const propValue = {
    title: " Confirm Booking",
    description: " Are you sure you want to confirm your   booking?",
    btnActions: btnActions,
    imgUrl: img1,
  };

  return (
    <section className=" ">
      <BookingStatusCard statusObj={propValue} />
    </section>
  );
};

export default ConfirmBooking;
