import React, { useState, useEffect, memo } from "react";
import { getSalonGallary } from "../../lib/salonProfileService";
import useImageLoader from "../../hooks/useImageLoader";
import { IoShareOutline } from "react-icons/io5";
import { IoApps } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {  setOpenSharePopState, setPopOpenState } from "../../store/slices/customSlice";
interface ISalonGallaryProps {
  gallery:string[]
}

const ImageItem: React.FC<{ src: string; alt: string; hVal: string }> = ({
  src,
  alt,
  hVal,
}) => {
  const { imageLoaded, imgRef } = useImageLoader(src);

  return (
    <img
      ref={imgRef}
      src={imageLoaded ? src : undefined} // Only set src if image is loaded
      alt={alt}
      className={`index ${
        imageLoaded ? "visible" : "invisible"
      } w-full ${hVal}  object-center `}
    />
  );
};


const SalonGallary: React.FunctionComponent<ISalonGallaryProps> = ({gallery}) => {
 const dispatch = useDispatch();
  const isPopActive = useSelector((state:RootState)=>state.custom.isPopOpen)
  console.log('images ',gallery)

  return (
    <section className={`${isPopActive ? "-z-50" : "z-0"}   hidden md:flex  relative justify-center items-center w-full gap-3 h-full`}>
      {gallery.length > 0 && (
        <>
          <div className={` h-max `}>
            <ImageItem
              src={gallery[0]}
              alt="Salon image 1"
              hVal="md:w-[548px] md:h-[278px] lg:w-[850px] xl:w-[1000px] lg:h-[436px] rounded-l-xl object-cover"
            />
          </div>
          <div className="col-span-1 grid grid-rows-2 gap-2 grow">
            {gallery.slice(1, 3).map((img, index) => (
              <div key={index} className="row-span-1">
                <ImageItem
                  src={img}
                  alt={`Salon image ${index + 2}`}
                  hVal="md:w-[242px] md:h-[136px] lg:w-[379px] xl:w-[548px] lg:h-[212px] rounded-r-xl object-cover"
                />
              </div>
            ))}
          </div>
          <div className={`${isPopActive?'-z-50':'z-20'}`}>

          <div className="absolute right-3  md:right-7 bottom-14 cursor-pointer " onClick={()=>{
            dispatch(setPopOpenState(true))
            dispatch(setOpenSharePopState(true))

          }}>
            <ButtonUi>
              <IoShareOutline  className="w-3 md:w-max"/>
              <h3 className="hidden md:block text-xs font-medium">Share</h3>
            </ButtonUi>
          </div>
          <div className="absolute right-3  md:right-7 bottom-3 cursor-pointer  ">
            <ButtonUi>
            <IoApps className="w-3 md:w-max" />
            <h3 className="hidden md:block  text-xs font-medium">see All Photos</h3>
            </ButtonUi>
          </div>
          </div>
        </>
      )}
    </section>
  );
};

export default memo(SalonGallary);

type ButtonUiProp = {
  children: React.ReactNode;
};

export const ButtonUi: React.FC<ButtonUiProp> = ({ children }) => {
  return (
    <div className="bg-white rounded-full md:rounded-md  flex md:gap-2 items-center px-[8px] py-1.5 md:px-3 md:py-[10px] ">
      {children}
    </div>
  );
};
