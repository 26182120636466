
import React,{useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/Layout/ScrollToTop";
import AppRoutes from "./routes/Routes";
import { Provider, useDispatch } from "react-redux";
import Login from "./components/Auth/Login";


import { useSelector } from "react-redux";
import store,{RootState} from "./store/store"; 
import { ApolloProvider } from "@apollo/client";
import { client } from "./service/mutations/userCustomerDetailsMutationService";

const App: React.FunctionComponent = () => {



 
// Add customer if the customer List is Empty 

// useEffect(()=>{


// if(){

// }

// },[])

// const addCustomer = async (variables:{
    
 
//   userId:string , 
//   fullName:string,
//   gender:string,
//   emailId: string,
//   date_of_birth:string,
//   phoneNumber: string,

// })=>{
// try {
//   console.log('Add Customer callled...')
//   const response = await addBookingCustomerDetails({
//     variables: variables
//   });
//   if(response) {
//     dispatch(setLoginState(false));
//     fetchShopProfessionals(dispatch, salonId);

//     fetchUserAllCustomers(dispatch,  user_values.user_id);
//     dispatch(setOpenCustDetailsState(false));
//     dispatch(setPopOpenState(false))
//     console.log('Response:', response);
//   }
// } catch (err) {
//   console.error('Error submitting form:', err);
// }


// }
  
  // if (window.location.pathname === "/") {
  //   window.location.href = "/lucas/hyderabad";
  // }

  return (
    <ApolloProvider client={client}>
      
    <Provider store={store}>
      <Router>
        <ScrollToTop>
         
          <AppRoutes />
        </ScrollToTop>
      </Router>
    </Provider>
    </ApolloProvider>
  );
};

export default App;
