import React, { useState } from "react";
import { Slots } from "../../types/bookingFlowTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setSlotErrorState, setTimeState } from "../../store/slices/bookingSlice";
import { setPopOpenState } from "../../store/slices/customSlice";

interface ISlotTimeProps {
  slots: Slots[];
}

const SlotTimeCard: React.FC<ISlotTimeProps> = ({ slots }) => {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState<string | number>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const slotsTimings: string[] = useSelector(
    (state: RootState) => state.booking.selectedTime
  );
  const {numberOfSlots} = useSelector((state:RootState)=>state.booking)

 

  // Function to check if consecutive slots are available
  const areConsecutiveSlotsAvailable = (
    selectedSlotIndex: number,
    numberOfSlotsToBlock: number
  ) => {
    for (let i = 0; i < numberOfSlotsToBlock; i++) {
      if (
        selectedSlotIndex + i >= slots.length || // Exceeds the slot array
        !slots[selectedSlotIndex + i].slot_available // Slot not available
      ) {
        return false;
      }
    }
    return true;
  };

  const handleClick = (selectedSlotIndex: number) => {
    
    const numberOfSlotsToBlock =numberOfSlots  

    // Clear states
    setSelectedTime("");
    setErrorMessage("");
    setShowPopup(false);

    // Check if selected slot and next consecutive slots are available
    if (!areConsecutiveSlotsAvailable(selectedSlotIndex, numberOfSlotsToBlock)) {
  
      dispatch(setSlotErrorState("Selected slots are not available. Please choose another slot."))
    dispatch(setPopOpenState(true))
      return;
    }

    // Block the selected slots in the UI and store the time
    setSelectedTime(slots[selectedSlotIndex].slot_id);

    // Update Redux with blocked slots
    const selectedSlotTimes = slots
      .slice(selectedSlotIndex, selectedSlotIndex + numberOfSlotsToBlock)
      .map((slot) => slot.slot_time);

    dispatch(setTimeState(selectedSlotTimes)); // Save selected times to Redux
  };

  return (
    <>
      {slots.map((item, key) => (
        <div
          key={key}
          className={` ${
            !item.slot_available || slotsTimings.includes(item.slot_time)
              ? "bg-DARKEST_GREY_L4"
              : ""
          } relative w-max border rounded-2xl px-3 lg:px-6 py-1 mt-6 flex flex-col justify-center`}
          onClick={() => {
            handleClick(key);
          }}
        >
          <div className="flex items-center gap-1 pt-1">
            <h3 className="text-texthero font-medium">{item.slot_time}</h3>
          </div>
          
 
        </div>
        
      ))}

      {/* Error Popup */}
     
    </>
  );
};

export default SlotTimeCard;
