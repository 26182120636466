import React, { useState, useEffect } from "react";
import AddupPrice from "./cart/AddupPrice";
import { FaRegTrashCan } from "react-icons/fa6";

import { LuDot } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { MdCelebration } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatTime } from "../../utils/validations";
import { removeService } from "../../store/slices/userCartSlice";
import { setPopOpenState } from "../../store/slices/customSlice";
import { useNavigate, useParams } from "react-router-dom";
import { extractShopIdFromUrl } from "../../utils/salonProfile";

interface ICartProps {
  summary: {
    title: string;
    ctaName: string;
    onclick: () => void;
  };
}

interface Offer {
  cart_value: number;
  offer_percentage: number;
}

const Cart: React.FunctionComponent<ICartProps> = ({ summary }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartValue = useSelector((state: RootState) => state.userCart);
  const cart = useSelector((state: RootState) => state.userCart.addedServices);

  const salonId = extractShopIdFromUrl();

  const { shopName, city } = useParams<{ shopName: string; city: string }>();


const isUserAuthenticated = useSelector((state:RootState)=>state.login.userAuthenticated)
  const offers = useSelector(
    (state: RootState) => state.salonOffers.offers || []
  );

  const [offerValues, setOfferValues] = useState<number[]>([]);
  const [offerPercentages, setOfferPercentages] = useState<number[]>([]);
  const [nearestOffer, setNearestOffer] = useState<number | null>(null);
  const [amountToAdd, setAmountToAdd] = useState<number | null>(null);
  const [discountPercentage, setDiscountPercentage] = useState<number | null>(
    null
  );

  useEffect(() => {
    // Set offer values and percentages whenever offers change
    const cartValues = offers.map((offer: Offer) => offer.cart_value);
    const percentages = offers.map((offer: Offer) => offer.offer_percentage);
    setOfferValues(cartValues);
    setOfferPercentages(percentages);
  }, [offers]);

  useEffect(() => {
    // Find nearest offer whenever cartValue.total or offerValues change
    findNearestOffer();
  }, [cartValue.total, offerValues]);

  useEffect(() => {
    if (cart.length < 1) {
      navigate(`/${shopName}/${city}/salonProfile?shopId=${salonId}`);
    }
  }, [cart, city, navigate, salonId, shopName]);

  useEffect(() => {
    if (discountPercentage !== null) {
      const totalPrice = cartValue.totalDiscount;
      const discountAmount = (cartValue.total / 100) * discountPercentage;
      console.log(
        "final discount",
        totalPrice + discountAmount,
        nearestOffer,
        offerPercentages
      );
    }
  }, [
    cartValue.total,
    cartValue.totalDiscount,
    discountPercentage,
    nearestOffer,
  ]);

  const findNearestOffer = () => {
    if (offerValues.length === 0) return;

    const nearest = offerValues.reduce((prev, curr) => {
      return Math.abs(curr - cartValue.total) < Math.abs(prev - cartValue.total)
        ? curr
        : prev;
    });
    setNearestOffer(nearest);
    calculateAmountToAdd(nearest);
    calculateDiscountPercentage(nearest);
  };

  const calculateAmountToAdd = (nearest: number) => {
    if (cartValue.total < nearest) {
      setAmountToAdd(nearest - cartValue.total);
    } else {
      setAmountToAdd(null);
    }

    console.log("final plan ", cartValue.total == nearest);
  };

  const calculateDiscountPercentage = (nearest: number) => {
    const index = offerValues.indexOf(nearest);
    if (index !== -1) {
      setDiscountPercentage(offerPercentages[index]);
    } else {
      setDiscountPercentage(null);
    }
  };

  console.log("cart value", cartValue);

  return (
    <div className="w-72 lg:w-96 gradBorder rounded-2xl p-5 lg:p-6 relative bg-white">
      <h1 className="text-DARKEST_GREY_L1 font-semibold text-center">
        {summary.title}
      </h1>
      <div className="md:max-h-52 xl:max-h-60 overflow-y-auto hideScrollBar">
        {cartValue.addedServices.map((service, index) => (
          <div className="pt-5 lg:pt-6" key={index}>
            <div className="flex items-center">
              <h2 className="capitalize">{service.addedService}</h2>
              <div className="flex flex-1" />
              <div className="flex items-center gap-3">
                <p className="">₹{service.price}</p>
                <FaRegTrashCan
                  className="text-DARKEST_GREY_L2 text-sm"
                  onClick={() => {
                    const services = {
                      addedService: service.addedService,
                      price: +service.price,
                      time: service.time,
                      serviceType: "",
                      discountAmount: service.discountAmount,
                      sub_category_id: service.sub_category_id,
                    };
                    dispatch(removeService(services));

                    dispatch(setPopOpenState(false));
                  }}
                />
              </div>
            </div>
            <div className="text-DARKEST_GREY_L2 text-sm pt-1.5 space-y-1.5">
              <p className="">{formatTime(+service.time)}</p>
              <p className="">{service.serviceType}</p>
            </div>
          </div>
        ))}
      </div>
      <IoIosArrowDown className="m-auto lg:text-lg" />
      <div className="py-4">
        {amountToAdd !== null && (
          <AddupPrice disPercentage={discountPercentage} price={amountToAdd} />
        )}
      </div>
      {/* {amountToAdd !== null && (
        <div className="text-green-600 font-semibold text-sm pt-3">
          <p>Add ₹{amountToAdd} more to avail the discount!</p>
        </div>
      )} */}
      <div className="text-green flex items-center">
        <h3 className="">Discount</h3>
        <div className="flex flex-1" />
        <p className="">₹{cartValue.totalDiscount}</p>
      </div>
      <div className="text-blue font-bold flex items-center pt-4">
        <h3 className="">Total</h3>
        <div className="flex flex-1" />
        <p className="">₹{cartValue.total}</p>
      </div>
      <div className="text-blue pt-1 text-sm flex items-center">
        <p className="">{cartValue.totalService} service</p>
        <LuDot className="text-xl" />
        <p className="">{formatTime(+cartValue.totaltime)}</p>
      </div>
      <div className={`${cartValue.totalDiscount < 1 && "hidden"}  h-10 py-6`}>
        <div
          className={` border-t border-b border-green bg-[#F1FFF1] py-2.5 absolute w-full left-0 h-10 flex gap-1 items-center justify-center`}
        >
          <MdCelebration />
          <p className="font-semibold text-xs text-center">
            Congratulations! ₹{cartValue.totalDiscount} saved so far.
          </p>
        </div>
      </div>
      <button
        className={`${isUserAuthenticated?'bg-black':'bg-[#19191896]'}  text-white font-semibold w-full mt-10 py-2 rounded-md`}
        onClick={summary.onclick}
        disabled={!isUserAuthenticated}

>
        {summary.ctaName}
      </button>
    </div>
  );
};

export default Cart;
