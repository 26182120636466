import React, { useEffect, useState } from "react";
import img1 from "../../assets/custom/loginSvg.png";
import InputPP from "../Common/InputPP";
import { loginInput } from "../../data/loginInputData";
import DropdownPP from "../Common/DropdownPP";
import {
  isValidInput,
  isValidMobileNo,
  isValidOtp,
} from "../../utils/validations";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { ApolloProvider, useMutation } from '@apollo/client';
import {
  setLoginState,
  setUserAuth,
  setUserDetails,
  setUserName,
} from "../../store/slices/authSlice";
import { getUserDetails, sendOtp, validateOtp } from "../../service/userAuthService";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addNewUsersState } from "../../store/slices/userAllCustomerDetailsSlice";
import { ADD_BOOKING_CUSTOMER_DETAILS,client } from "../../service/mutations/userCustomerDetailsMutationService";
import { AddBookingCustomerDetailsResponse, AddBookingCustomerDetailsVariables } from "../../types/salonCustomerTypes";
import { setOpenCustDetailsState, setPopOpenState } from "../../store/slices/customSlice";
import { fetchUserAllCustomers } from "../../service/userCustomerDetailsService";
import { fetchShopProfessionals } from "../../service/salonProfessionalService";
import { extractShopIdFromUrl } from "../../utils/salonProfile";

interface ILoginProps {
  isCustForm?: boolean;
}

type LoginState = {
  name: string;
  mobile: string;
  dob: string;
  otp: string;
  gender: string;
};

const initialValue = {
  name: "",
  mobile: "",
  otp: "",
  dob: "",
  gender: "",
};

const Login: React.FC<ILoginProps> = ({ isCustForm = false }) => {

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState<LoginState>(initialValue);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [user_values, setUser_values] = useState<{
    user_id:''
  }>({user_id:''})

  const genderVal: string[] = ["Male", "Female", "Unisex"];
  const user_id = localStorage.getItem("user_id")
  const salonId = extractShopIdFromUrl();

  const [addBookingCustomerDetails, { data, loading, error }] = useMutation<
    AddBookingCustomerDetailsResponse,
    AddBookingCustomerDetailsVariables
  >(ADD_BOOKING_CUSTOMER_DETAILS);

  useEffect(()=>{
    if(user_id) {
      setUser_values(JSON.parse(user_id))
    }
  },[user_id])


  const validateInput = (name: string, value: string | number) => {
    let error = "";
    switch (name) {
      case "name":
      case "dob":
      case "gender":
        error = isValidInput(
          value,
          "text",
          name.charAt(0).toUpperCase() + name.slice(1)
        );
        break;
      case "mobile":
        error = isValidMobileNo(+value);
        break;
      case "otp":
        error = isValidOtp(+value);
        break;
      default:
        break;
    }
    return error;
  };
  const [isLogged, setIsLogged] = useState<boolean>(false)


  useEffect(()=>{
    if(isLogged&& user_values.user_id){
      addCustomer({
        userId: user_values.user_id , // This should be dynamic in your app
        fullName: inputs.name,
        gender: inputs.gender,
        emailId: '',
        date_of_birth:inputs.dob,
        phoneNumber: inputs.mobile,
      },)
    }
  },[   inputs,isLogged, user_values])

  const onChangeHandle = (name: string, value: string | number) => {
    const error = validateInput(name, value);
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (!otpSent) {
      Object.keys(inputs).forEach((key) => {
        if (key !== "otp") {
          const error = validateInput(key, inputs[key as keyof LoginState]);
          if (error) {
            newErrors[key] = error;
          }
        }
      });
    } else {
      const error = validateInput("otp", inputs.otp);
      if (error) {
        newErrors["otp"] = error;
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addCustomer = async (variables:{
    
 
      userId:string , 
      fullName:string,
      gender:string,
      emailId: string,
      date_of_birth:string,
      phoneNumber: string,
   
  })=>{
    try {
      console.log('Add Customer callled...')
      const response = await addBookingCustomerDetails({
        variables: variables
      });
      if(response) {
        // dispatch(setLoginState(false));
        fetchShopProfessionals(dispatch, salonId);

       fetchUserAllCustomers(dispatch,  user_values.user_id);
        dispatch(setOpenCustDetailsState(false));
        dispatch(setPopOpenState(false))
        console.log('Response:', response);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    }


  }

  const handleSendOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return toast.error("Please Fill All details");
    try {
      setProcessing(true);

      const otpSent = await sendOtp(inputs.mobile, false, inputs.name,
        inputs.dob);

      toast[otpSent ? "success" : "error"](
        ` ${otpSent ? "Login Successful" : "Provide a valid phone number"}`
      );

      console.log('otp sent values',otpSent)

      if (otpSent) {
        // setShowOtp(true);
        // setOtpSent(true);
        // setProcessing(false);
        setIsLogged(true)
        dispatch(setLoginState(false));
        // getUserDetails().then((userDetails) => {
        //   if (userDetails) {
        //     setUser_values(userDetails);
        // localStorage.setItem('user_id',JSON.stringify(userDetails))
    
        //   }
        // });

        addCustomer({
          userId: user_values.user_id , // This should be dynamic in your app
          fullName: inputs.name,
          gender: inputs.gender,
          emailId: '',
          date_of_birth:inputs.dob,
          phoneNumber: inputs.mobile,
        },)
        localStorage.setItem("user_details", JSON.stringify(inputs));
        localStorage.setItem("is_loggedin_", JSON.stringify(true));
     
        dispatch(setUserAuth(true));
        dispatch(setPopOpenState(false))
        dispatch(setUserName(inputs.name));
        dispatch(setUserDetails(inputs));
        setProcessing(false);
        // setInputs(initialValue);
      } else {
        setProcessing(false);
      }
    } catch (error) {
      setProcessing(false);
    }
  };

 

  const handleSaveData = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return toast.error("Please Fill All details");
    // try {
    //   const response = await addBookingCustomerDetails({
    //     variables: {
    //       userId: user_values.user_id , // This should be dynamic in your app
    //       fullName: inputs.name,
    //       gender: inputs.gender,
    //       emailId: '',
    //       date_of_birth:inputs.dob,
    //       phoneNumber: inputs.mobile,
    //     },
    //   });
    //   if(response) {
    //     fetchShopProfessionals(dispatch, salonId);

    //     fetchUserAllCustomers(dispatch,  user_values.user_id);
    //     dispatch(setOpenCustDetailsState(false));
    //     dispatch(setPopOpenState(false))
    //     console.log('Response:', response);
    //   }
    // } catch (err) {
    //   console.error('Error submitting form:', err);
    // }

    try{
      addCustomer({
        userId: user_values.user_id , // This should be dynamic in your app
        fullName: inputs.name,
        gender: inputs.gender,
        emailId: '',
        date_of_birth:inputs.dob,
        phoneNumber: inputs.mobile,
      },)

    }
    catch(err){
      console.log('error in adding cuistomer',err)
    }

  };

  const handleVerifyOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setProcessing(true);

        const otpVerified = await validateOtp(
          inputs.mobile,
          false,
          inputs.otp,
          inputs.name,
          inputs.dob
        );
        toast[otpVerified ? "success" : "error"](
          `${otpVerified ? "Successfully Logged in" : "Please enter valid OTP"}`
        );
        setTimeout(() => {
          if (otpVerified) {
            setIsLogged(true)
            dispatch(setLoginState(false));
            localStorage.setItem("user_details", JSON.stringify(inputs));
            localStorage.setItem("is_loggedin_", JSON.stringify(true));
         
            dispatch(setUserAuth(true));
            dispatch(setPopOpenState(false))
            dispatch(setUserName(inputs.name));
            dispatch(setUserDetails(inputs));
            setProcessing(false);
            // setInputs(initialValue);
          } else {
            setProcessing(false);
          }
        }, 1400);
      } catch (error) {
        console.error("OTP Verification Failed:", error);
        setProcessing(false);
        dispatch(setPopOpenState(false))

      }
      // Add logic to verify OTP here
    }
  };



  return (
   
    <React.Fragment>
      <div className="w-full z-[999] absolute top-0">
        <ToastContainer className="toast-container" />
      </div>
      <section
        className={`flex items-center rounded-2xl md:mx-14 lg:mx-0 h[27rem] ${
          !isCustForm && "lg:h-[30rem] bg-white drop-shadow-md "
        } `}
      >
        <div
          className={`{${
            isCustForm ? "s" : " px-7  py-8 md:py-0  md:w-[55%] lg:w-max "
          }md:px-10 lg:px-24`}
        >
          <div
            className={`${
              isCustForm && "hidden"
            } absolute right-5 top-5 flex md:hidden items-center justify-center bg-hero w-6 h-6 md:w-8 md:h-8 rounded-full`}
            onClick={() =>{

           dispatch(setLoginState(false))
           dispatch(setPopOpenState(false))
          
        }
          }
          >
            <IoClose className={` md:text-lg text-white`} />
          </div>
          <h1
            className={`${
              isCustForm && "mt-3"
            } lg:text-xl xl:text-2xl font-semibold`}
          >
            {isCustForm?'Customer Details':'Login'}
          </h1>
          <form className="pt-2 space-y-2.5 lg:space-y-3">
            {loginInput.map((item, index) => (
              <div key={index}>
                {item.type !== "dropDown"  ? (
                  <InputPP
                    name={item.name}
                    type={item.type}
                    isCustClass={isCustForm}
                    custClassName={` ${isCustForm&&item.type=='date'&&'hidden'} text-sm lg:text-base px-4 py-2 xl:py-3 rounded-md`}
                    placeholder={item.type === "date" ? "DOB" : item.title}
                    value={inputs[item.name as keyof LoginState] || ""}
                    istextArea={false}
                    onChange={(value) => onChangeHandle(item.name, value)}
                  />
                ) : (
                  <DropdownPP
                    custClsName="text-sm lg:text-base px-4 md:px-0"
                    isSpecFunType="spegender"
                    dropDefVal="Select your Gender"
                    options={genderVal}
                    value={inputs.gender}
                    onChangeFun={(value) => onChangeHandle("gender", value)}
                  />
                )}

                {errors[item.name] && (
                  <div className="text-red w-max text-xs md:text-sm pt-0.5">
                    {errors[item.name]}
                  </div>
                )}
              </div>
            ))}
            {/* {showOtp && (
              <div className={`${isCustForm && "hidden"}`}>
                <InputPP
                  name="otp"
                  type="number"
                  custClassName="text-sm lg:text-base px-4 py-2 xl:py-3 rounded-md"
                  placeholder="Enter OTP"
                  value={inputs.otp}
                  istextArea={false}
                  onChange={(value) => onChangeHandle("otp", value)}
                />
                {errors["otp"] && (
                  <div className="text-red text-xs md:text-sm pt-0.5">
                    {errors["otp"]}
                  </div>
                )}
              </div>
            )} */}
            <p
              className={`${
                !isCustForm && "hidden"
              } text-blue italic text-xs lg:w-80 pt-3 lg:pt-0 pb-4 md:pb-5`}
            >
              *We will use this information for communicating details about your
              bookings.
            </p>
            <button
              className={`${
                isCustForm && "hidden"
              } bg-hero text-white w-full py-1 lg:py-2 xl:py-3 font-medium rounded-md`}
              onClick={otpSent&&false ? handleVerifyOtp : handleSendOtp}
            >
              {processing
                ? otpSent
                  ? "Verifying..."
                  : "Logining..."
                : otpSent&&false
                ? "Verify OTP"
                : "Login"}
            </button>

            <button
              className={`${
                !isCustForm && "hidden"
              } bg-texthero text-white  w-full py-1 md:py-2 rounded`}
              onClick={handleSaveData}
            >
              Save Details
            </button>
          </form>
        </div>
        <div className="flex flex-1" />
        <div className={` ${isCustForm ? "hidden" : "hidden md:block"} `}>
          <div
            className="absolute right-5 top-5 flex items-center justify-center bg-hero w-8 h-8 rounded-full"
            onClick={() => dispatch(setLoginState(false))}
          >
            <IoClose className="text-lg text-white" />
          </div>
          <img
            src={img1}
            alt="Login"
            className="w-72 md:w-96 rounded-r-2xl md:h-[27rem] lg:h-[30rem] object-cover"
          />
        </div>
      </section>
    </React.Fragment>
 
  );
};

export default Login;
