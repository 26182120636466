// Function to get Month and Year
type DateObject  = {
    month:string,
    year:number
}

type DayObject = {
    date:number|string,
    fullDate:string,
    day:string
}

export const getMonthAndYear = ():DateObject => {
    const today = new Date();
    const month = today.toLocaleString('en-US', { month: 'short' }); // Aug
    const year = today.getFullYear(); // 2024
    const combinedMonthandYear:DateObject = {
        month: month,
        year:year
    }
    return combinedMonthandYear
};

// Function to get Date and Day
export const getDateAndDay = (): DayObject[] => {
    const today = new Date();
    const dates: DayObject[] = [];

    for (let i = 0; i < 14; i++) {
        const currentDate = new Date(today);
        currentDate.setDate(today.getDate() + i);

        const day = currentDate.getDate().toString().padStart(2, '0'); // 09
        const dayName = currentDate.toLocaleString('en-US', { weekday: 'short' }); // Mon

        // Create the object with correct types
        const combinedDateandDay: DayObject = {
            date: day, // No type mismatch here
            day: dayName,
            fullDate:currentDate.toISOString().slice(0, 10)
        };

        dates.push(combinedDateandDay);
    }

    return dates;
};



export function convertMinutesToHoursAndMinutes(minutes:number) {
    if (isNaN(minutes) || minutes < 0) {
        return "Invalid input";
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
        return `${remainingMinutes} minute${
            remainingMinutes !== 1 ? "s" : ""
        }`;
    } else if (remainingMinutes === 0) {
        return `${hours} hr${hours !== 1 ? "s" : ""}`;
    } else {
        return `${hours} hr${
            hours !== 1 ? "s" : ""
        } ${remainingMinutes} Minute${remainingMinutes !== 1 ? "s" : ""}`;
    }
}

  
export function calculateSlotsFromTotalTime(
    totalTimeInMinutes:number,
    slotDurationInMinutes:number,
) {
    let numberOfSlots = Math.floor(
        totalTimeInMinutes / slotDurationInMinutes,
    );
    const remainingMinutes = totalTimeInMinutes % slotDurationInMinutes;

    // If there are remaining minutes, add one more slot
    if (remainingMinutes > 0) {
        numberOfSlots++;
    }

    return {
        numberOfSlots,
        remainingMinutes,
    };
}

export function convertTo24Hour(timeStr: string): string {
    const [time, period] = timeStr.split(' ');
    let [hoursStr, minutesStr] = time.split(':');
    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }