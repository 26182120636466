import React from "react";
 
// import { whyStylindata } from "../../../data/whyStylin";
import WhyStylinCard from "../Ui/WhyStylinCard";
import { whyStylindata } from "../../data/whyStylinData";

// Component Definition
const WhyStylin: React.FC = () => {
    return (
        <main className="">
            <h1 className="text-xl md:text-2xl lg:text-4xl xl:text-[45px] text-texthero text-center">
                Why Choose STYLIN ?
            </h1>

            {/* Placeholder for description */}
            {/* <p className=" text-center py-6 md:py-8 lg:py-20 font-normal text-sm md:text-base lg:text-xl text-[#4F4F4F]">
                With Stylin, you're not just choosing a salon you're choosing a 
                lifestyle of confidence, 
                sophistication, and self-expression
            </p> */}

            <div className="pt-7 lg:pt-14 flex justify-center md:block lg:flex">
                <WhyStylinCard data={whyStylindata} />
            </div>
        </main>
    );
};

export default WhyStylin;
