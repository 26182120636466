import React,{useEffect} from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaTelegram, FaMailBulk } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import PopUpLayout from '../Layout/PopUpLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setOpenSharePopState, setPopOpenState } from '../../store/slices/customSlice';

interface ShareButtonProps {
  url: string;
  title: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ url, title }) => {
    const dispatch =  useDispatch()
  const encode = (text: string) => encodeURIComponent(text);
  const isPopOpen  = useSelector((state:RootState)=>state.custom.openSharePop)

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encode(url)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encode(url)}&text=${encode(title)}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encode(url)}&title=${encode(title)}`,
    whatsapp: `https://wa.me/?text=${encode(title)}%20${encode(url)}`,
    telegram: `https://t.me/share/url?url=${encode(url)}&text=${encode(title)}`,
    email: `mailto:?subject=${encode(title)}&body=${encode(url)}`
  };

  const platforms: Array<keyof typeof shareLinks> = [
    'facebook',
    'twitter',
    'linkedin',
    'whatsapp',
    'telegram',
    'email'
  ];

  useEffect(() => {

    // Add the class to prevent body scrolling when popup is open
    if(isPopOpen) {

        // document.body.classList.add('no-scroll');
    }

    // Cleanup function to remove the class when popup is closed
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isPopOpen]);

  return (
    <PopUpLayout>
      <div className="z-50 bg-white flex flex-col p-6 md:p-10 rounded-xl drop-shadow-lg relative">
        <IoClose className='text-2xl absolute top-3 right-3 cursor-pointer' onClick={()=>{
            dispatch(setOpenSharePopState(false))
            dispatch(setPopOpenState(false))
        }} />
        <h1 className="font-medium text-DARKEST_GREY_L2 md:text-xl xl:text-[22px] text-center py-5">
          Share the Love!
        </h1>
        <div className="flex gap-3 ">
          {platforms.map((platform) => (
            <a
              key={platform}
              className='text-DARKEST_GREY_L2 hover:text-texthero'
              href={shareLinks[platform]}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Share on ${platform.charAt(0).toUpperCase() + platform.slice(1)}`}
            >
              {platform === 'facebook' && <FaFacebook className="text-blue-600" size={24} />}
              {platform === 'twitter' && <FaTwitter className="text-blue-400" size={24} />}
              {platform === 'linkedin' && <FaLinkedin className="text-blue-700" size={24} />}
              {platform === 'whatsapp' && <FaWhatsapp className="text-green-500" size={24} />}
              {platform === 'telegram' && <FaTelegram className="text-blue-500" size={24} />}
              {platform === 'email' && <FaMailBulk className="text-gray-600" size={24} />}
            </a>
          ))}
        </div>
      </div>
    </PopUpLayout>
  );
};

export default ShareButton;
