import React, { useState } from "react";
// import { ISalonReviewCard } from "../../types/salonServicesTypes";
import { IoIosStar } from "react-icons/io";
import { LuDot } from "react-icons/lu";
import { ReviewsState } from "../../types/salonServicesTypes";
// import { initializeSalonReviews } from "../../data/salonReviewsData";
import { getSalonReviews } from "../../lib/salonReviewLib";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

 

const SalonReviewCard: React.FC= () => {
  const reviewsData = useSelector((state:RootState)=>state.salonReviews)
 
  //extractd latest review date
  const lastUpdatedDate = new Date (reviewsData.ratings?reviewsData?.ratings[reviewsData.ratings?.length-1].created_at:'2024-09-23')

  // converting to the localstring date
  const formattedDate = `${lastUpdatedDate.toLocaleDateString('en-US', { month: 'long' })} ${lastUpdatedDate.getDate()}, ${lastUpdatedDate.getFullYear()}`;

  return (
    
    <section className="">
      <h3 className="text-blue text-xs italic font-medium pb-3 lg:pb-6">
        Last review updated on {formattedDate}
      </h3>
      {reviewsData.ratings?.map((review, key) => (
        <div className=" lg: pb-4 ">
          <div className="flex items-start gap-1.5 md:gap-3   ">
            <div className="w-8 lg:w-9 h-8 lg:h-9 bg-DARKEST_GREY_L4 flex items-center justify-center rounded-full" >
              <p className="font-semibold capitalize">
               {review.user_name?.charAt(0)}
              </p>
            </div>
            {/* <img src={review.} alt="Image url" className="w-8 lg:w-9 h-8 lg:h-9" /> */}
            <div className="">
              <p className="text-texthero font-medium capitalize">{review.user_name}</p>
              <p className="lg: text-[11px] text-DARKEST_GREY_L2">
                {review.created_at}
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3  gap-3">

              {review.review_tags?.map((item,key)=>(
                
                <div className=" bg-DARKEST_GREY_L4 rounded-full w-max px-2 py-1 my-1 " key={key}>
                <p className="capitalize font-medium text-[11px] text-texthero">
                {item}
                </p>
              </div>
              ))}
              </div>

            </div>
          </div>
          <div className="pt-1.5">
            <div className="flex items-center gap-1">
              {[...Array(review.rating)].map((_, index) => (
                <IoIosStar key={index} className="text-hero2 lg:text-xl" />
              ))}
            </div>
            {/* <div className="flex items-center  pt-1 ">
                {review.services.map((item,key)=>(
                    <div className="flex items-center text-DARKEST_GREY_L2 text-xs">

                        <LuDot className={`${key+1==1&&'hidden'} text-lg`}/> 
                        <p className=" ">
                            {item}
                        </p>
                    </div>
                ))}
            </div> */}
            <p className="text-DARKEST_GREY_L1  lg: text-sm xl:text-base pt-2   pb-4 lg:w-96">
                {review.review}
            </p>
          </div>
          <hr
            className={`${
              reviewsData.ratings?.length == key + 1 && "hidden"
            } text-DARKEST_GREY_L2`}
          />
        </div>
      ))}
    </section>
  );
};
export default SalonReviewCard;
