import salonImg1 from "../assets/salon/hero1.svg";
import salonImg2 from "../assets/salon/hero2.svg";
import salonImg3 from "../assets/brand/brandGallary/img2.png"


export interface Salon {
  id: number;
  salonImg: string;
  title: string;
  rating: string;
  location: string;
  timings: string;
  holiday: string;
  link?:string,
  distance: string;
  isOpen: boolean;
  salonType: string;
}

export const salonData: Salon[] = [
  {
    id: 1,
    salonImg: salonImg1,
    link:'/famous/hyderabad/salonProfile?shopId=1',
    title: "Famous Men's Salon",
    rating: "4.5",
    location: "Mahindra University, Madhurapalli",
    timings: "10:00 am - 8:00 pm",
    holiday: "Tuesday Closed",
    distance: "5km",
    isOpen: true,
    salonType: "Men’s Salon",
  },
  {
    id: 2,
    salonImg: salonImg2,
    link:'/sriveennal/hyderabad/salonProfile?shopId=2',
    title: "Sri Vennala Ladies Salon",
    rating: "4.3",
    location: "Mahindra University, Madhurapalli",
    timings: "10:00 am - 8:00 pm",
    holiday: "Monday Closed",
    distance: "5km",
    isOpen: false,
    salonType: "Women's Salon",
  },
  {
    id: 3,
    salonImg: salonImg3,
    title: "Lucas Salon",
    link:'/lucas/hyderabad/salonProfile?shopId=3',
    rating: "4.3",
    location: "Jublihills , Hyderabad",
    timings: "10:00 am - 8:00 pm",
    holiday: "Monday Closed",
    distance: "5km",
    isOpen: true,
    salonType: "Women's Salon",
  },
];
