import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { AppDispatch } from '../store/store';
import { fetchSubCategoriesStart, fetchSubCategoriesSuccess, fetchSubCategoriesFailure } from '../store/slices/salonServicesSlice';
import { fetchPackagesStart, fetchPackagesSuccess, fetchPackagesFailure } from '../store/slices/salonPackagesSlice';
import { APOLLO_CLIENT_URI } from '../config/config';


const client = new ApolloClient({
  uri: `${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

const GET_SHOP_SUBCATEGORIES_QUERY = gql`
 query GetShopSubCategories($shopId: ID!, $filter: String) {
  getShopSubCategories(shop_id: $shopId, filter: $filter) {
    category_name
    subcategories {
      sub_category_id
      sub_category_name
      service_cost
      default_time
      description
      shop_service_id
      default_customization
      shop_id
      category_name
      customer_category
      is_customization
      customizations {
        sub_customization_id
        sub_customization_name
        service_cost
        alloted_time
        description
        shop_sub_category_id
      }
      average_rating
      rating_count
      discount_percent
      discount_price
      service_image_url
    }
  }
}
`;

const GET_PACKAGES_QUERY = gql`
query GetPackages($getPackagesShopId2: ID!) {
  getPackages(shop_id: $getPackagesShopId2) {
    id
    serviceType
    serviceObj {
      package_name
      price
      discount_price
      description
      allocated_time
      services_count
      customer_category
      average_rating
      rating_count
      discount_percent
      shop_id
    }
  }
}
`;

export const fetchShopSubCategories = async (
  dispatch: AppDispatch,
  shopId: string,
  filter:string='male'
): Promise<void> => {
  dispatch(fetchSubCategoriesStart());

  try {
    const { data } = await client.query({
      query: GET_SHOP_SUBCATEGORIES_QUERY,
      variables: { shopId,filter  },
    });


    dispatch(fetchSubCategoriesSuccess(data.getShopSubCategories));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(fetchSubCategoriesFailure(error.message));
    } else {
      dispatch(fetchSubCategoriesFailure("An unknown error occurred"));
    }
  }
};

export const fetchPackages = async (
  dispatch: AppDispatch,
  getPackagesShopId2: string
): Promise<void> => {
  dispatch(fetchPackagesStart());

  try {
    const { data } = await client.query({
      query: GET_PACKAGES_QUERY,
      variables: { getPackagesShopId2 },
    });
   

    dispatch(fetchPackagesSuccess(data.getPackages));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(fetchPackagesFailure(error.message));
    } else {
      dispatch(fetchPackagesFailure("An unknown error occurred"));
    }
  }
};